<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
              <div class="text-800 mb-1">
                  <span class="text-primary pi pi-info-circle mb-3" v-tooltip=tooltip></span> {{subtitle}}
              </div>
              
              <div class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
                  <div class="p-fluid mt-2 w-full md:mt-0 md:w-5">
                      
                      <div style="display: flex; align-items: center; justify-content: space-between">
                        <InputText v-model="filter" class="m-2" placeholder="Pesquisar" @change="search($event)" />
                        <Button class="p-button-text p-button-text p-button-rounded" icon="pi pi-cog" @click="openBasic" />
                      </div>

                      <Dialog header="Configurações Gerais" v-model:visible="displayBasic">
                          <div class="">
                            <div>
                              <div class="m-3">
                                  <label class="m-3">Mínima</label>
                                  <InputMask v-model="minRoute" mask="99:99" tabindex="4" placeholder="HH:mm"/>
                              </div>
                              <div class="m-3">
                                  <label class="m-3">Máxima</label>
                                  <InputMask v-model="maxRoute" mask="99:99" tabindex="4" placeholder="HH:mm" />
                              </div>
                            </div>
                            <Divider />
                            <div>
                              <div class="m-3">
                                  <label class="m-3">Cidades</label>
                                  <MultiSelect
                                    v-model="cityActual"
                                    :options="cityFilters"
                                    optionLabel="label"
                                    :placeholder="this.cityFilters?.length > 0 ? 'Escolha' : 'Nenhum registro encontrado'"
                                    :class="{'p-invalid': submitted && !form.city}"
                                    :filter="true"
                                    @filter="loadCity"
                                    :showClear="true"
                                  />
                              </div>
                              <div class="m-3">
                                  <label class="m-3">Unidade</label>
                                  <MultiSelect
                                    v-model="unityActual"
                                    :options="unityFilters"
                                    optionLabel="label"
                                    :placeholder="this.unityFilters?.length > 0 ? 'Escolha' : 'Nenhum registro encontrado'"
                                    :class="{'p-invalid': submitted && !form.unity}"
                                    :filter="true"
                                    @filter="loadUnity"
                                    :showClear="true"
                                  />
                              </div>
                            </div>
                            <Divider />
                          </div>
                          <div class="field-checkbox">
                              <div class="m-1" style="display: flex; align-items: center; justify-content: space-between;">
                                <Checkbox v-model="advance" :binary="true" />
                                <div style="margin-left: 10px;">
                                  <label for="binary">Considerar rota com antecedência</label>
                                </div>
                              </div>
                              <div style="display: flex; align-items: center; justify-content: space-between;">
                                <Checkbox v-model="available" :binary="true" />
                                <div style="margin-left: 10px;">
                                  <label for="binary">Disponibilizar a terceiros</label>
                                </div>
                              </div>
                          </div>
                          <div style="display: flex; align-items: center;">
                            <label style="margin-left: 30px; margin-right: 10px; min-width: 100px;">{{advance ? 'Com quantos dias?' : ''}}</label>
                            <InputText v-model="days" :style="{display: advance ? 'flex' : 'none'}" />
                          </div>
                          <div style="display: flex; align-items: center; margin-top: 10px;">
                            <Checkbox v-model="receive" :binary="true" />
                            <div style="margin-left: 10px;">
                              <label for="binary">Receber de terceiros</label>
                            </div>
                          </div>
                          <div class="flex mt-5" style="display: flex; flex-wrap: wrap; justify-content: space-between;">
                            <Button label="Salvar filtros" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
                            <Button label="Limpar filtros" icon="pi pi-times" class="p-button-text" @click="cleanFilters" />
                          </div>
                      </Dialog>
                      
                      <Dialog header="Editar Rota" v-model:visible="displayEditRoute">
                        <div class="m-3">
                          <div class="m-3">
                              <label class="m-3" for="name">Nome</label>
                              <InputText id="name" v-model.trim="form.name" required="true" autofocus autocomplete="off" :class="{'p-invalid': submitted && !form.name}" />
                          </div>

                          <div class="m-3">
                              <label class="m-3" for="classCouncilAcronym">Cidade</label>
                              <MultiSelect
                                v-model="form.city"
                                :filter="true"
                                :options="city"
                                optionLabel="label"
                                :placeholder="this.city?.length > 0 ? 'Escolha' : 'Nenhum registro encontrado'"
                                :class="{'p-invalid': submitted && !form.city?.length}"
                                @filter="loadCity"
                                :showClear="true"
                              />
                          </div>
                          <div class="m-3">
                              <label class="m-3" for="classCouncil">Unidade</label>
                              <MultiSelect
                                v-model="form.unity"
                                :filter="true"
                                :options="unity"
                                optionLabel="label"
                                :placeholder="this.unity?.length > 0 ? 'Escolha' : 'Nenhum registro encontrado'"
                                :class="{'p-invalid': submitted && !form.unity?.length}"
                                @filter="loadUnity"
                                :showClear="true"
                              />
                          </div>

                          <div class="m-3">
                              <label class="m-3" for="specialty">Data de vencimento</label>
                              <InputMask id="specialty" mask="99/99/9999" placeholder="00/00/0000" style="max-width: 250px; min-width: 250px;" v-model.trim="form.dueDate" autocomplete="off" :class="{'p-invalid': submitted && !form.dueDate}" />
                          </div>
                          <small class="p-error" v-if="submitted && (!form.name || !form.dueDate || !unity || !city)">Preencha todos os campos.</small>
                        </div>

                        <div class="m-3">
                            <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="editRoute" />
                            <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto"  @click="this.displayEditRoute = false"/>
                        </div>
                      </Dialog>
                      
                      <Dialog header="Informações da Rota" v-model:visible="displayInfoRoute" :style="{width: '50vw'}">
                        <div class="field">
                          <label style="fontWeight: 600; margin-right: 5px;">Nome:</label>
                          <label>{{this.infoRoute?.name}}</label>
                        </div>
                        <div class="field">
                          <label style="fontWeight: 600; margin-right: 5px;">Cidades:</label>
                          <label>{{this.infoRoute?.ids_cities}}</label>
                        </div>
                        <div class="field">
                          <label style="fontWeight: 600; margin-right: 5px;">Unidades:</label>
                          <label>{{this.infoRoute?.ids_units}}</label>
                        </div>
                        <div class="field">
                          <label style="fontWeight: 600; margin-right: 5px;">Data de vencimento:</label>
                          <label>{{formatDate(this.infoRoute.due_date)}}</label>
                        </div>
                        <div class="field">
                          <label style="fontWeight: 600; margin-right: 5px;">Status:</label>
                          <label>{{this.infoRoute.status === 'available' ? 'Disponível' : 'Em andamento'}}</label>
                        </div>
                        <div class="field">
                          <label style="fontWeight: 600; margin-right: 5px;">Data da criação:</label>
                          <label>{{formatDate(this.infoRoute.created_at)}}</label>
                        </div>
                      </Dialog>
                  </div>
                  <div class="mt-2 ml-auto md:mt-0">
                      <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2"  @click="$router.push('/security/newRoutes')" />
                  </div>
              </div>

              <Toast/>
             <AppLoadingWrapper v-if="loading" />
              <div class="col-12 p-fluid painel painel-2 ">
                  <TabView :scrollable="true" v-model:activeIndex="active" @click="filter = ''; this.resetList();">
                      <TabPanel header="Próximas Rotas" @click="active = 0;">
                          <DataTable
                            v-if="!loading"
                            dataKey="id"
                            :value="nextRoutes"
                            :row-hover="true"
                            responsiveLayout="scroll"
                            breakpoint="640px"
                            :rows="10"
                            @row-click="openModalInfoRoute($event)"
                            style="cursor: pointer"
                          >
                            <template #empty> Nenhum registro encontrado. </template>

                            <template #loading> Carregando registros. Aguarde ... </template>
                            <Column field="id" header="Identificador" />
                            <Column field="name" header="Nome" />
                            <Column field="due_date" header="Data de vencimento">
                              <template #body="slotProps">
                                <label :style="{ color: validationDueDate(slotProps.data?.dueDate) === 'vencido' ? 'red' : 'green' }">
                                  {{slotProps.data?.dueDate ? formatDate(slotProps.data.dueDate) : '-'}}
                                </label>
                              </template>
                            </Column>
                            <Column field="status" header="Status">
                              <template #body="slotProps">
                                {{slotProps.data?.status === 'available' ? 'Disponível' : ''}}
                              </template>
                            </Column>
                            <Column bodyClass="text-right" headerStyle="width: 10rem;">
                              <template #body="slotProps">
                                <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary" @click="toggleMenu($event, slotProps.data)" />
                              </template>
                            </Column>
                          </DataTable>
                          <Menu ref="menu" :model="actionItems" :popup="true" />
                          <Paginator
                            :rows="perPage"
                            :totalRecords="totalNextAvailable"
                            :v-model:first="pageNextAvailable"
                            @page="onPage($event)"
                            template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                            currentPageReportTemplate="Página {currentPage} de {totalPages}"
                          />
                      </TabPanel>
                      <TabPanel header="Rotas disponíveis" @click="active = 1">
                          <DataTable
                            dataKey="id"
                            :value="routesAvailable"
                            :row-hover="true"
                            responsiveLayout="scroll"
                            breakpoint="640px"
                            :rows="10"
                            @row-click="openModalInfoRoute($event)"
                            style="cursor: pointer"
                          >
                            <template #empty> Nenhum registro encontrado. </template>

                            <template #loading> Carregando registros. Aguarde ... </template>
                            <Column field="id" header="Identificador" />
                            <Column field="name" header="Nome" />
                            <Column field="due_date" header="Data de vencimento">
                              <template #body="slotProps">
                                <label :style="{ color: validationDueDate(slotProps.data?.dueDate) === 'vencido' ? 'red' : 'green' }">
                                  {{formatDate(slotProps.data?.dueDate)}}
                                </label>
                              </template>
                            </Column>
                            <Column field="status" header="Status">
                              <template #body="slotProps">
                                {{slotProps.data?.status === 'available' ? 'Disponível' : ''}}
                              </template>
                            </Column>
                            <Column bodyClass="text-right" headerStyle="width: 10rem;">
                              <template #body="slotProps">
                                <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary" @click="toggleMenu($event, slotProps.data)" />
                              </template>
                            </Column>
                          </DataTable>
                          <Menu ref="menu" :model="actionItems" :popup="true" />
                          <Paginator
                            :rows="perPage"
                            :totalRecords="totalAvailable"
                            :v-model:first="pageAvailable"
                            @page="onPage($event)"
                            template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                            currentPageReportTemplate="Página {currentPage} de {totalPages}"
                          />
                      </TabPanel>
                      <TabPanel header="Rotas em andamento" @click="active = 2">
                          <DataTable
                            dataKey="id"
                            :value="routesProgress"
                            :row-hover="true"
                            responsiveLayout="scroll"
                            breakpoint="640px"
                            :rows="10"
                            @row-click="openModalInfoRoute($event)"
                            style="cursor: pointer"
                          >
                            <template #empty> Nenhum registro encontrado. </template>

                            <template #loading> Carregando registros. Aguarde ... </template>
                            <Column field="id" header="Identificador" />
                            <Column field="name" header="Nome" />
                            <Column field="due_date" header="Data de vencimento">
                              <template #body="slotProps">
                                {{formatDate(slotProps.data?.dueDate)}}
                              </template>
                            </Column>
                            <Column field="status" header="Status">
                              <template #body="slotProps">
                                {{slotProps.data?.status === 'progress' ? 'Em andamento' : ''}}
                              </template>
                            </Column>
                            <Column bodyClass="text-right" headerStyle="width: 10rem;">
                              <template #body="slotProps">
                                <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary" @click="toggleMenu($event, slotProps.data)" />
                              </template>
                            </Column>
                          </DataTable>
                          <Paginator
                            :rows="perPage"
                            :totalRecords="totalProgress"
                            :v-model:first="pageProgress"
                            @page="onPage($event)"
                            template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                            currentPageReportTemplate="Página {currentPage} de {totalPages}"
                          />
                      </TabPanel>
                      <TabPanel header="Histórico de movimentação" @click="active = 3">
                          <DataTable
                            dataKey="id"
                            :value="historyMove"
                            :row-hover="true"
                            responsiveLayout="scroll"
                            breakpoint="640px"
                            :rows="10"
                          >
                            <template #empty> Nenhum registro encontrado. </template>

                            <template #loading> Carregando registros. Aguarde ... </template>
                            <Column field="id" header="Identificador"></Column>
                            <Column field="updated_at" header="Última atualização">
                              <template #body="slotProps">
                                {{$filters.formatDate(slotProps.data?.updated_at)}}
                              </template>
                            </Column>
                            <Column field="status" header="Status">
                              <template #body="slotProps">
                                <label style="fontWeight: bold;">{{slotProps.data?.previous_status === 'available' ? 'Disponível' : 'Em andamento'}}</label>
                                para
                                <label style="fontWeight: bold;">{{slotProps.data?.new_status === 'available' ? 'Disponível' : 'Em andamento'}}</label>
                              </template>
                            </Column>
                          </DataTable>
                          <Paginator
                            :rows="perPage"
                            :totalRecords="totalHistory"
                            :v-model:first="pageHistory"
                            @page="onPage($event)"
                            template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                            currentPageReportTemplate="Página {currentPage} de {totalPages}"
                          />
                      </TabPanel>
                      <TabPanel header="Unidades pendentes" @click="active = 4">
                          <DataTable
                            dataKey="id"
                            :value="unityPending"
                            :row-hover="true"
                            responsiveLayout="scroll"
                            breakpoint="640px"
                            :rows="10"
                          >
                            <template #empty> Nenhum registro encontrado. </template>

                            <template #loading> Carregando registros. Aguarde ... </template>
                            <Column field="id" header="Identificador"></Column>
                            <Column field="name" header="Unidade"></Column>
                          </DataTable>
                          <Paginator
                            :rows="perPage"
                            :totalRecords="totalPending"
                            :v-model:first="pagePending"
                            @page="onPage($event)"
                            template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
                            currentPageReportTemplate="Página {currentPage} de {totalPages}"
                          />
                      </TabPanel>
                  </TabView>
              </div>
            </div>
        </div>
    </div>
</template>

<script>

import SecurityService from '../../services/SecurityService';
import dayjs from 'dayjs';
import { getTenant } from '../../common/storage';
import { formatDropDawn } from '../../utils/Mask';

export default {
  data() {
    return {
                  actionItems: [
                {
                    label: 'Alterar',
                    icon: 'pi pi-pencil',
                    command: () => this.openModalEditRoute(this.actionRecord)
                },
                {
                    label: 'Avançar',
                    icon: 'pi pi-arrow-circle-right',
                    disabled: () => this.active != 1,
                    command: () => this.activeRoute(this.actionRecord.id)
                }
            ],
      filter: '',
      active: 0,
      actionRecord: [],
      displayBasic: false,
      displayInfoRoute: false,
      displayEditRoute: false,
      infoRoute: null,
      advance: false,
      available: false,
      receive: false,
      loading: false,
      days: '',
      cityActual: null,
      unityActual: null,
      city: null,
      cityFilters: null,
      unity: null,
      unityFilters: null,
      maxRoute: '',
      minRoute: '',
      form: {},
      submitted: false,
      totalAvailable: 0,
      pageAvailable: 1,
      totalPageAvailable: 0,
      totalNextAvailable: 0,
      pageNextAvailable: 1,
      totalNextPageAvailable: 0,
      totalProgress: 0,
      pageProgress: 1,
      totalPageProgress: 0,
      totalHistory: 0,
      pageHistory: 1,
      totalPageHistory: 0,
      totalPending: 0,
      pagePending: 1,
      totalPagePending: 0,
      perPage: 10,
      timeout: null,
      tooltip: "Gerenciamento de Rotas",
      subtitle: "Rotas",
      nextRoutes: [],
      nextRoutesDefault: [],

      routesAvailable: [],
      routesAvailableDefault: [],

      routesProgress: [],
      routesProgressDefault: [],

      historyMove: [],
      historyMoveDefault: [],

      unityPending: [],
      unityPendingDefault: [],
    };
  },
  mounted() {
    this.$service = new SecurityService('/seguranca/rotas');
    this.$serviceUnitsPending = new SecurityService('/customer/branches/not-routes');
    this.$serviceHistoryMove = new SecurityService('/seguranca/rotas/historico');
    this.$serviceUnits = new SecurityService('/customer/branches');
    this.$serviceCities = new SecurityService('/cidades');
    this.$serviceStatusAvailable = new SecurityService(`/seguranca/rotas/0/available`);
    this.$serviceStatusProgress = new SecurityService(`/seguranca/rotas/0/progress`);
    this.load();
  },
  watch: {
    page() {
      this.load();
    },
  },
  methods: {
    toggleMenu(event, data) {
      this.actionRecord = data
      this.$refs.menu.toggle(event);
    },
    async editRoute() {
      this.submitted = true;
      try {
        const formatCity = this.form.city?.map((v) => v.value);
        const formatUnity = this.form.unity?.map((v) => v.value);
        const newName = String(this.form.dueDate).split('/');
        const convertISO = new Date(parseInt(newName[2]),parseInt(newName[1])-1,parseInt(newName[0]));
        const body = {
          idsCities: formatCity,
          idsUnits: formatUnity,
          dueDate: convertISO,
          name: this.form.name,
        };
        const stringBody = JSON.stringify(body);
        await this.$service.save(stringBody, this.infoRoute?.id);
        this.$toast.add({severity:'success', summary: 'Rota salva com sucesso!', life: 3000});
        this.submitted = false;
        this.displayEditRoute = false;
        this.form = {};
        this.load();
      } catch (error) {
        this.$toast.add({severity:'error', summary: 'Problemas ao salvar a rota!', life: 3000});
      }
    },
    openModalInfoRoute(infoRouteSelected) {
      const filterCitySelected = this.city.filter((v) => infoRouteSelected?.data?.ids_cities?.includes(v?.value)).map((v) => v?.label);
      const filterUnitySelected = this.unity.filter((v) => infoRouteSelected?.data?.ids_units?.includes(v?.value)).map((v) => v?.label);
      let allCitys = '';
      filterCitySelected?.map((v, i) => {
        if (i + 1 === filterCitySelected?.length) {
          allCitys = `${allCitys} ${v}.`
        } else {
          allCitys = `${allCitys} ${v},`;
        }
      })
      let allUnity = '';
      filterUnitySelected?.map((v, i) => {
        if (i + 1 === filterUnitySelected?.length) {
          allUnity = `${allUnity} ${v}.`
        } else {
          allUnity = `${allUnity} ${v},`;
        }
      })
      this.infoRoute = {
        ...infoRouteSelected?.data,
        ids_cities: allCitys,
        ids_units: allUnity,
      };
      this.displayInfoRoute = true;
    },
    openModalEditRoute(infoRouteSelected) {
      const dueDateFormat = new Date(infoRouteSelected?.dueDate);
      if (!infoRouteSelected?.dueDate || !dueDateFormat) {
        return null;
      }
      const formatAddHours = dayjs(dueDateFormat).add(3, 'hour');
      if (!formatAddHours) {
        return null;
      }
      const formatDatesDayJS = dayjs(formatAddHours).format('DD/MM/YYYY') || '';
      const filterCitySelected = (this.city || []).filter((v) => infoRouteSelected?.idsCities?.includes(v?.value));
      const filterUnitySelected = (this.unity || []).filter((v) => infoRouteSelected?.idsUnits?.includes(v?.value));
      this.form = {
        name: infoRouteSelected?.name || '',
        dueDate: formatDatesDayJS,
        city: filterCitySelected || [],
        unity: filterUnitySelected || [],
      };
      this.displayEditRoute = true;
      this.infoRoute = infoRouteSelected;
    },
    validationDueDate(dateString) {
      const date1 = dayjs(new Date(dateString));
      const date2 = dayjs(new Date());
      const validationDiffInSeconds = date1.diff(date2, 's');
      if (String(validationDiffInSeconds).includes('-')) {
        return 'vencido';
      }
      return 'disponível';
    },
    formatDate(dateString) {
      if (dateString && new Date(dateString)) {
        const formatAddHoursDates = dayjs(new Date(dateString)).add(3, 'hour');
        const formatAddHoursHours = dayjs(new Date(dateString)).add(3, 'hour');
        const formatDates = dayjs(formatAddHoursDates).format('DD/MM/YYYY');
        const formatHours = dayjs(formatAddHoursHours).format('HH:mm');
        return `${formatDates} às ${formatHours}h`;
      }
      return '-';
    },
    async onPage(event) {
      const formatFilter = Array.isArray(this.filter) ? this.filter : [this.filter];
      const params = { page: event.page + 1, filter: formatFilter };
      if ([0, 1].includes(this.active)) {
        this.$serviceStatusAvailableDays = new SecurityService(`/seguranca/rotas/${this.days || '0'}/available`);
        const { data } = await this.$serviceStatusAvailableDays.findAll(null, params);
        if (this.active === 1) {
          this.pageAvailable = event.page + 1;
          this.routesAvailable = data?.items || [];
          this.totalAvailable = data?.meta?.totalItems || 0;
          this.totalPageAvailable = data?.meta?.totalPages || 0;
        } else {
          this.pageNextAvailable = event.page + 1;
          this.nextRoutes = data?.items || [];
          this.totalNextAvailable = data?.meta?.totalItems || 0;
          this.totalNextPageAvailable = data?.meta?.totalPages || 0;
        }
      }
      if (this.active === 2) {
        const { data } = await this.$serviceStatusProgress.findAll(null, params);
        this.pageProgress = event.page + 1;
        this.routesProgress = data?.items || [];
        this.totalProgress = data?.meta?.totalItems || 0;
        this.totalPageProgress = data?.meta?.totalPages || 0;
      }
      if (this.active === 3) {
        const { data } = await this.$serviceHistoryMove.findAll(null, params);
        this.pageHistory = event.page + 1;
        this.historyMove = data?.items || [];
        this.totalHistory = data?.meta?.totalItems || 0;
        this.totalPageHistory = data?.meta?.totalPages || 0;
      }
      if (this.active === 4) {
        const { data } = await this.$serviceUnitsPending.findAll(null, params);
        this.pagePending = event.page + 1;
        this.unityPending = data?.items || [];
        this.totalPending = data?.meta?.totalItems || 0;
        this.totalPagePending = data?.meta?.totalPages || 0;
      }
    },
    async loadCity(event) {
      if (this.timeout) {
          clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        const params = { filter: [event.value] };
        const { data } = await this.$serviceCities.findAll(null, params);
        const formatCity = formatDropDawn(data?.items || []);
        if (this.displayBasic) {
          this.cityFilters = formatCity;
        } else {
          this.city = formatCity;
        }
      }, 500);
    },
    async loadUnity(event) {
      if (this.timeout) {
          clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        const params = { filter: [event.value] };
        const { data } = await this.$serviceUnits.findAll(null, params);
        const formatUnity = formatDropDawn(data?.items || []);
        if (this.displayBasic) {
          this.unityFilters = formatUnity;
        } else {
          this.unity = formatUnity;
        }
      }, 500);
    },
    async search() {
      const params = { filter: [this.filter] };
      if ([0, 1].includes(this.active)) {
        this.$serviceStatusAvailableDays = new SecurityService(`/seguranca/rotas/${this.days ||'0'}/available`);
        const { data } = await this.$serviceStatusAvailableDays.findAll(null, params);
        if (this.active === 1) {
          this.pageNextAvailable = 1;
          this.routesAvailable = data?.items || [];
          this.totalAvailable = data?.meta?.totalItems || 0;
          this.totalPageAvailable = data?.meta?.totalPages || 0;
        } else {
          this.pageNextAvailable = 1;
          this.nextRoutes = data?.items || [];
          this.totalNextAvailable = data?.meta?.totalItems || 0;
          this.totalNextPageAvailable = data?.meta?.totalPages || 0;
        }
      }
      if (this.active === 2) {
        const { data } = await this.$serviceStatusProgress.findAll(null, params);
        this.pageProgress = 1;
        this.routesProgress = data?.items || [];
        this.totalProgress = data?.meta?.totalItems || 0;
        this.totalPageProgress = data?.meta?.totalPages || 0;
      }
      if (this.active === 4) {
        const { data } = await this.$serviceUnitsPending.findAll(null, params);
        this.pagePending = 1;
        this.unityPending = data?.items || [];
        this.totalPending = data?.meta?.totalItems || 0;
        this.totalPagePending = data?.meta?.totalPages || 0;
      }
    },
    openBasic() {
      this.displayBasic = true;
    },
    save() {
      this.displayBasic = false;
      this.getRoutesAvailable('filters');
    },
    resetList() {
      if ([0, 1].includes(this.active)) {
        this.getRoutesAvailable();
      }
      if (this.active === 2) {
        this.getRoutesInProgress();
      }
      if (this.active === 3) {
        this.getHistoryMove();
      }
      if (this.active === 4) {
        this.getUnityPending();
      }
    },
    cleanFilters() {
      this.displayBasic = false;
      this.advance = false;
      this.days = '';
      this.load();
    },
    async activeRoute(idRoute) {
      // Jogar rota de Disponível para Pendente
      try {
        await this.$service.startRoute(idRoute);
        this.$toast.add({severity:'success', summary: 'Rota iniciada com sucesso!', life: 3000});
        this.getRoutesInProgress();
        this.getRoutesAvailable();
      } catch (error) {
        this.$toast.add({severity:'error', summary: 'Problemas ao atualizar a rota para Em Andamento', life: 3000});
      }
    },
    async getRoutesAvailable(type) {
      try {
        // Todas as rotas (Próximas rotas/Disponíveis)
        const responseRoutes = await this.$serviceStatusAvailable.findAll(null, {});
        this.routesAvailable = responseRoutes?.data?.items || [];
        this.pageAvailable = 1;
        this.totalAvailable = responseRoutes?.data?.meta?.totalItems || 0;
        this.totalPageAvailable = responseRoutes?.data?.meta?.totalPages || 0;

        if (type) {
          this.$serviceStatusAvailableDays = new SecurityService(`/seguranca/rotas/${this.days || '0'}/available`);
          const { data } = await this.$serviceStatusAvailableDays.findAll(null, {});
          this.nextRoutes = data?.items || [];
          this.pageNextAvailable = 1;
          this.totalNextAvailable = data?.meta?.totalItems || 0;
          this.totalNextPageAvailable = responseRoutes?.data?.meta?.totalPages || 0;
        } else {
          this.nextRoutes = responseRoutes?.data?.items || [];
          this.pageNextAvailable = 1;
          this.totalNextAvailable = responseRoutes?.data?.meta?.totalItems || 0;
          this.totalNextPageAvailable = responseRoutes?.data?.meta?.totalPages || 0;
        }
      } catch (error) {
        this.$toast.add({severity:'error', summary: 'Problemas ao listar Próximas Rotas/Rotas Disponíveis', life: 3000});
      }
    },
    async getCityAndUnity() {
      // Todas as unidades
      try {
        const responseUnity = await this.$serviceUnits.findAll(null, {});
        const formatUnity = formatDropDawn(responseUnity?.data?.items || []);
        this.unity = formatUnity;
        this.unityFilters = formatUnity;
      } catch (error) {
        this.$toast.add({severity:'error', summary: 'Problemas ao listar unidades', life: 3000});
      }
      // Todas as cidades
      try {
        const responseCity = await this.$serviceCities.findAll(null, {});
        const formatCity = formatDropDawn(responseCity?.data?.items || []);
        this.city = formatCity;
        this.cityFilters = formatCity;
      } catch (error) {
        this.$toast.add({severity:'error', summary: 'Problemas ao listar cidades', life: 3000});
      }
    },
    async getHistoryMove() {
      try {
        // Histórico de Movimentação
        const { data } = await this.$serviceHistoryMove.findAll(null, {});
        this.historyMove = data?.items || [];
        this.pageHistory = 1;
        this.totalHistory = data?.meta?.totalItems || 0;
        this.totalPageHistory = data?.meta?.totalPages || 0;
      } catch (error) {
        this.$toast.add({severity:'error', summary: 'Problemas ao listar histórico de movimentações', life: 3000});
      }
    },
    async getRoutesInProgress() {
      try {
        // Rotas em andamento
        const { data } = await this.$serviceStatusProgress.findAll(null, {});
        this.routesProgress = data?.items || [];
        this.pageProgress = 1;
        this.totalProgress = data?.meta?.totalItems || 0;
        this.totalPageProgress = data?.meta?.totalPages || 0;
      } catch (error) {
        this.$toast.add({severity:'error', summary: 'Problemas ao listar rotas em andamento', life: 3000});
      }
    },
    async getUnityPending() {
      try {
        // Lista de Unidades Pendentes
        const apiKey = getTenant() ? { 'x-api-key': getTenant() } : {};
        const { data } = await this.$serviceUnitsPending.findAll(null, {}, apiKey);
        this.unityPending = data?.items || [];
        this.pagePending = 1;
        this.totalPending = data?.meta?.totalItems || 0;
        this.totalPagePending = data?.meta?.totalPages || 0;
      } catch (error) {
        this.$toast.add({severity:'error', summary: 'Problemas ao listar unidades pendentes', life: 3000});
      }
    },
    async load() {
      this.loading = true;
      this.getRoutesAvailable();
      this.getCityAndUnity();
      this.getHistoryMove();
      this.getRoutesInProgress();
      this.getUnityPending();
      this.loading = false;
    },
  },
};
</script>